import { Component } from '@angular/core';
import { NbLoginComponent, NbAuthResult } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent extends NbLoginComponent {
  loadingLargeGroup: boolean = false;
  success: boolean = false;
  failed: boolean = false;
    user: any = {
        username: 'admin',
        password: 'Admin!123',
        clientId: environment.keycloack.clientId,
        clientSecret: environment.keycloack.clientSecret,
        scope: environment.keycloack.scope,
        grant_type: environment.keycloack.grant_type
    }

    login() {
      this.loadingLargeGroup = true;
      this.failed = false;
      this.success = false;
        this.service.authenticate('email', this.user)
          .subscribe((authResult: NbAuthResult) => {
            if (authResult.isSuccess()) {
              this.failed = false;
              this.success = true;
              this.loadingLargeGroup = false;
                this.router.navigateByUrl('/pages/dashboard');
            }else{
              this.loadingLargeGroup = false;
              this.failed = true;
              this.success = false;
            }
          });
      }
}