import { Component, OnDestroy,Inject,  OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService, NbTokenService } from '@nebular/auth';
import { UserModel } from '../../../@data/models/user.model';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: UserModel;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  profileTitle: string = "Buttons.Profile";
  logoutTitle: string = "Buttons.LougOout";
  confirmLogout: string ="Confirm.Logout";

  currentTheme = 'default';

  userMenu = [];

  constructor(private sidebarService: NbSidebarService,
             private nbMenuService: NbMenuService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              //private userService: UserData,
              private authService: NbAuthService,
              @Inject(NB_WINDOW) private window,
              private router: Router,
              private layoutService: LayoutService,
              private translate: TranslateService,
              private tokenService: NbTokenService,
              private breakpointService: NbMediaBreakpointsService
            ) {
    /*this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        console.log("token", token);
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        }
      });*/
      //this.authService.logout("");
      //this.authService.getToken().subscribe((data) => console.log("Token:: ", data));
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
        }
      });
    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
    */

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


    this.setUserMenu();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { //Live reload
        this.setUserMenu();
    });

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if(this.logoutTitle == title && this.window.confirm(`${this.confirmLogout}`) == true){
          //console.log("rst", rst);
          this.logout();
        }
      });
  }
  
  setUserMenu()
  {
    this.profileTitle = this.translate.instant("Buttons.Profile");
    this.confirmLogout = this.translate.instant("Confirm.Logout");
    this.logoutTitle = this.translate.instant("Buttons.LougOout");
    
    this.userMenu = [ { title: this.profileTitle}, { title: this.logoutTitle } ]
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout(){
    this.authService.logout('email');
    //this.tokenService.clear();
    this.router.navigateByUrl('/auth/login');
    //this.router.navigateByUrl('/auth/logout');
  }
}