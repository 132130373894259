<h1 id="title" class="title">{{ 'Title.Login'| translate }}</h1>
<p class="sub-title">{{ 'Title.LoginDescripion'| translate }}</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group" *ngIf="!success">
    <label class="label" for="input-email">{{ 'Fields.EmailOrUserNameOrPhone'| translate }} :</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.username"
           #email="ngModel"
           name="email"
           [readonly]="loadingLargeGroup"
           id="input-email"
           placeholder="{{ 'Fields.EmailOrUserNameOrPhone'| translate }} "
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="error-message" *ngIf="email.errors?.required">
        {{ 'Fields.RequiredFiled'| translate }} 
      </p>
    </ng-container>
  </div>

  <div class="form-control-group" *ngIf="!success">
    <label class="label" for="input-password">{{ 'Fields.Password'| translate }} :</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           [readonly]="loadingLargeGroup"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="{{ 'Fields.Password'| translate }}"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="error-message" *ngIf="password.errors?.required">
        {{ 'Fields.PasswordRequired'| translate }}
      </p>
      <p class="error-message" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        {{ 'Fields.PasworFormat' | translate  : {  minLength: getConfigValue('forms.validation.password.minLength') } }}
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group" *ngIf="!loadingLargeGroup && !success">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">{{ 'Buttons.RemeberMe'| translate }}</nb-checkbox>
    <a class="forgot-password" routerLink="../request-password">{{ 'Buttons.ForgottenPassword' | translate }}</a>
  </div>

  <button nbButton
        *ngIf="!success"
          fullWidth
          status="success"
          [disabled]="submitted || !form.valid"
          [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large" nbSpinnerMessage=""
          [class.btn-pulse]="submitted">
    {{ 'Buttons.Login' | translate }}
  </button>
  <nb-alert status="danger" *ngIf="failed">{{ 'Events.LoginFailed' | translate }}</nb-alert>
  <nb-alert status="success" *ngIf="success">{{ 'Events.LoginSuccess' | translate }}</nb-alert>
  
</form>