import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpKeycloackInterceptor implements HttpInterceptor {

  token: string;

  constructor(
    protected service: NbAuthService) {


    }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   if(request.url.indexOf(environment.keycloack.baseEndpoint) > -1){
        const authReq = request.clone({
            headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded')
          });
        return next.handle(authReq);
    }
    if(request.url.indexOf(environment.keycloack.logoutEndpoint) > -1){
        const authReq = request.clone({
            headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded')
          });
        return next.handle(authReq);
    }

    if(this.service.isAuthenticated()){
      this.service.getToken().subscribe((tk)=>{
        this.token =  tk.getValue();
      });
      
      request.headers.set("Authorization",this.token);
      
    }
    return next.handle(request)
    .pipe(
          catchError((error: HttpErrorResponse) => {
             let errorMsg = '';
             if (error.error instanceof ErrorEvent) {
                console.log('This is client side error');
                errorMsg = `Error: ${error.error.message}`;
             } else {
                console.log('This is server side error');
                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
             }
             console.log(errorMsg);
             return throwError(errorMsg);
          })
    );
  }
}